import React, { useEffect, useRef, useState } from 'react'
import { Popup, Swiper, Image, Toast } from 'react-vant'
import styles from './index.module.scss'
import { SampleDetailType } from '../SelectProducts/model'

type Props = {
  show: boolean
  sampleCode: string
  selectedData: [] | SamplesType[]
  onClose: () => void
  onChange: (e: SamplesType[]) => void
}
export default function SampleDetailPopup(props: Props) {
  const popupContent = useRef<HTMLDivElement>(null)
  const [sampleDetail, setSampleDetail] = useState<SampleDetailType | null>(null)
  const [selectedSample, setSelectedSample] = useState<SamplesType>()
  const [selectedStyles, setSelectedStyles] = useState<[] | SampleStyleType[]>([])
  const [sampleStyle, setSampleStyle] = useState<SampleStyleType>({
    colourUrl: '',
    colourName: '',
    age: '',
    size: '',
  })

  useEffect(() => {
    if (!props.sampleCode) return
    const data = props.selectedData.find(e => e.relatedSampleCode === props.sampleCode)

    setSelectedSample(data)
    setSelectedStyles(data?.relatedSampleStyle || [])
  }, [props.sampleCode, props.selectedData, props.show])

  useEffect(() => {
    setSampleDetail({
      sampleName: '花世界',
      albums: [
        'https://dapianlaile.oss-cn-chengdu.aliyuncs.com/APPstatic/%E5%9B%BE%E7%89%87/%E5%B0%81%E9%9D%A2/f1.jpg',
        'https://dapianlaile.oss-cn-chengdu.aliyuncs.com/APPstatic/%E5%9B%BE%E7%89%87/%E5%B0%81%E9%9D%A2/f2.jpg',
        'https://dapianlaile.oss-cn-chengdu.aliyuncs.com/APPstatic/%E5%9B%BE%E7%89%87/%E5%B0%81%E9%9D%A2/f3.jpg',
        'https://dapianlaile.oss-cn-chengdu.aliyuncs.com/APPstatic/%E5%9B%BE%E7%89%87/%E5%B0%81%E9%9D%A2/f4.jpg',
      ],
      sampleId: 815,
      categoryId: 24,
      categoryName: '分类',
      sampleCode: 'FL-000815',
      coverPictureUrl:
        'https://dapianlaile-test.oss-cn-chengdu.aliyuncs.com/APP/2024-02-04/20240204163544404421016.jpg?w=1600&h=832',
      isChoose: 1,
      sampleTag: [
        {
          id: '394',
          tagValue: '大师系列',
          valueDescribe: '',
          tagId: '54',
        },
      ],
      colourList: [
        {
          colourUrl:
            'https://dapianlaile.oss-cn-chengdu.aliyuncs.com/APPstatic/%E5%9B%BE%E7%89%87/%E6%9C%8D%E8%A3%85/%E7%94%B7%E7%AB%A5%E6%9C%8D%E8%A3%85.jpg',
          colourName: '男童服装',
        },
        {
          colourUrl:
            'https://dapianlaile.oss-cn-chengdu.aliyuncs.com/APPstatic/%E5%9B%BE%E7%89%87/%E6%9C%8D%E8%A3%85/%E5%A5%B3%E7%AB%A5%E6%9C%8D%E8%A3%85.jpg',
          colourName: '女童服装',
        },
      ],
      ageList: ['3岁', '5岁', '8岁', '8岁+'],
      sizeList: ['80', '90', '110', '120', '130', '140', '150'],
    })
    popupContent.current?.scrollTo({ top: 0, behavior: 'smooth' })
  }, [props.sampleCode, props.show])

  const onChange = (value: any, type: 'colour' | 'age' | 'size') => {
    if (type === 'colour') {
      return setSampleStyle({
        ...sampleStyle,
        colourUrl: value.colourUrl,
        colourName: value.colourName,
      })
    }
    setSampleStyle({ ...sampleStyle, [type]: value })
  }
  const onAdd = () => {
    if (!sampleStyle.colourName || !sampleStyle.age || !sampleStyle.size)
      return Toast('请选择完整的服装风格~')
    setSelectedStyles([...selectedStyles, sampleStyle])
    setSampleStyle({
      colourUrl: '',
      colourName: '',
      age: '',
      size: '',
    })
  }
  const onDel = (index: number) => {
    setSelectedStyles(selectedStyles.filter((_, i) => i !== index))
  }

  const onClose = () => {
    setSelectedStyles([])
    setSampleStyle({
      colourUrl: '',
      colourName: '',
      age: '',
      size: '',
    })
    props.onClose()
  }

  const onConfirm = () => {
    let data: SamplesType[] = []
    let relatedSampleStyle: SampleStyleType[] =
      sampleStyle.colourName && sampleStyle.age && sampleStyle.size
        ? [...selectedStyles, sampleStyle]
        : selectedStyles

    if (selectedSample) {
      const sample: SamplesType = {
        ...(selectedSample as SamplesType),
        relatedSampleStyle,
      }
      data = props.selectedData.map(e => {
        if (e.relatedSampleCode === props.sampleCode) {
          e = sample
        }
        return e
      })
    } else {
      const sample = {
        relatedSampleCode: sampleDetail?.sampleCode as string,
        relatedSampleName: sampleDetail?.sampleName as string,
        relatedSampleUrl: sampleDetail?.coverPictureUrl as string,
        relatedSampleStyle,
      }
      data = [...props.selectedData, sample]
    }

    props.onChange(data)
    onClose()
  }
  return (
    <Popup
      visible={props.show}
      style={{ width: '100%', height: '100%' }}
      position="bottom"
      closeOnClickOverlay
      onClose={onClose}
    >
      <div className={styles.popup}>
        <div className={styles.popupContent} ref={popupContent}>
          <Swiper>
            {sampleDetail?.albums.map(el => (
              <Swiper.Item key={el}>
                <Image className={styles.img} fit="cover" src={el} />
              </Swiper.Item>
            ))}
          </Swiper>
          <div className={styles.name}>{sampleDetail?.sampleName}</div>
          <div className={styles.selectedBox}>
            <div className={styles.selectedTitle}>已选：</div>
            <div className={styles.selected}>
              {selectedStyles.length ? (
                selectedStyles.map((el, index) => (
                  <div key={index} className={styles.selectedItem}>
                    <span
                      className={styles.selectedLabel}
                    >{`${el.colourName},${el.age},${el.size}`}</span>
                    <div
                      className={'iconfont icon-shanshu-1 ' + styles.selectedDel}
                      onClick={() => onDel(index)}
                    ></div>
                  </div>
                ))
              ) : (
                <div className={styles.selectedNone}>去选择些风格吧~</div>
              )}
            </div>
          </div>
          <div className={styles.title}>服装风格</div>
          <div className={styles.content}>
            {sampleDetail?.colourList.map(el => (
              <div
                key={el.colourName}
                className={`${styles.colourItem} ${
                  sampleStyle.colourName === el.colourName && styles.colourItemSelected
                }`}
                onClick={() => onChange(el, 'colour')}
              >
                <Image className={styles.colourItemImg} src={el.colourUrl} fit="cover"></Image>
                <div className={styles.colourItemLabel}>{el.colourName}</div>
              </div>
            ))}
          </div>
          <div className={styles.title}>年龄</div>
          <div className={styles.content}>
            {sampleDetail?.ageList.map(el => (
              <div
                key={el}
                className={`${styles.contentItem} ${
                  sampleStyle.age === el && styles.contentItemSelected
                }`}
                onClick={() => onChange(el, 'age')}
              >
                {el}
              </div>
            ))}
          </div>
          <div className={styles.title}>服装尺码</div>
          <div className={styles.content}>
            {sampleDetail?.sizeList.map(el => (
              <div
                key={el}
                className={`${styles.contentItem} ${
                  sampleStyle.size === el && styles.contentItemSelected
                }`}
                onClick={() => onChange(el, 'size')}
              >
                {el}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.footerBody}>
            <div className={styles.footerBodyClose} onClick={onClose}>
              关闭
            </div>
            <div className={styles.footerBodyAdd} onClick={onAdd}>
              加入已选库
            </div>
            <div className={styles.footerBodyConfirm} onClick={onConfirm}>
              确认加入
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}
