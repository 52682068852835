import React, { useMemo, useEffect, useState, useContext } from 'react'
import styles from './index.module.scss'
import { Image, ImagePreview, Stepper, Toast } from 'react-vant'
import { handleImage } from '@/utils'
import { sampleQueryById, SampleType, SampleDetailType } from '../SelectProducts/model'
import { QuestionnaireContext } from '@/components/QuestionnaireContext'
import SampleDetailPopup from '../SampleDetailPopup'

export interface Props {
  product: SampleType
  selectedData: [] | SamplesType[]
  type: 0 | 1
  onChange: (e: SamplesType[]) => void
  onShowSampleDetail: (sampleCode: string) => void
}

const ProductItem = (props: Props) => {
  const [productNum, setProductNum] = useState(0)
  const [currentSampleDetail, setCurrentSampleDetail] = useState<SampleStyleType[]>([])
  const originData = useContext(QuestionnaireContext)
  // const sample: SamplesType = {
  //   relatedSampleCode: props.product.sampleCode,
  //   relatedSampleName: props.product.sampleName,
  //   relatedSampleUrl: props.product.coverPictureUrl,
  // }

  useEffect(() => {
    // 这里是拿到当前样片已选的数据
    const currentProduct = props.selectedData.find(
      el => el.relatedSampleCode === props.product.sampleCode,
    )
    setCurrentSampleDetail(currentProduct?.relatedSampleStyle || [])
    console.log('currentProductcurrentProductcurrentProduct', currentProduct)

    setProductNum(currentProduct?.relatedSampleStyle?.length || 0)
  }, [props.product, props.selectedData])

  // const onChange = (value: number | null) => {
  //   let selectedData = JSON.parse(JSON.stringify(props.selectedData)) as SamplesType[]
  //   const index = selectedData.findIndex(el => el.relatedSampleCode === props.product.sampleCode)

  //   if (value) {
  //     // sample.relatedSampleNum = value
  //     if (index === -1) {
  //       props.type === 0 ? (selectedData = [sample]) : selectedData.push(sample)
  //     } else {
  //       // selectedData[index].relatedSampleNum = value
  //     }
  //   } else {
  //     //数量改为0，已选数据删除该条，如果已选里面有的话
  //     if (index !== -1)
  //       selectedData = selectedData?.filter(e => e.relatedSampleCode !== props.product.sampleCode)
  //   }

  //   props.onChange(selectedData)
  // }
  // const openImagePreview = (sampleId: string | number) => {
  //   Toast.loading('加载中...')
  //   sampleQueryById({ data: { sampleId } }).then(res => {
  //     Toast.clear()
  //     ImagePreview.open({
  //       images: res.data?.albums,
  //       closeable: true,
  //       closeIconPosition: 'top-left',
  //     })
  //   })
  // }
  const onLink = () => {
    window.jWeixin.miniProgram.navigateTo({
      url: '/pages/open-contact/open-contact?subtitle=该样品不在您购买的套餐内，请咨询客服购买',
    })
  }

  return (
    <div className={styles['popup-item']}>
      <div className={styles['popup-img']}>
        <Image
          className={styles['popup-item__img']}
          fit={'cover'}
          src={handleImage(props.product.coverPictureUrl)}
          onClick={() => props.onShowSampleDetail(props.product.sampleCode)}
        />
        <div className={styles['popup-img__icon'] + ' iconfont icon-quanping'}></div>
      </div>
      <div className={styles['popup-item__box']}>
        <div className={styles['popup-item__box__big']}>
          <div className={styles['popup-item__title']}>{props.product.sampleName}</div>
          {originData.isUpdate ? (
            props.product.isChoose === 1 && props.product.sampleTag.length ? (
              // <Stepper
              //   value={productNum}
              //   theme="round"
              //   buttonSize="22"
              //   integer
              //   min={0}
              //   onChange={onChange}
              //   style={{
              //     '--rv-stepper-button-round-theme-color': '#FC8142',
              //     width: '100px',
              //     display: 'flex',
              //     justifyContent: 'flex-end',
              //   }}
              // />
              <div className={styles.numbertext}>
                <span>已选</span>
                {productNum}
                <span>套</span>
              </div>
            ) : (
              <div className={styles['popup-item__link']} onClick={onLink}>
                咨询店内工作人员
              </div>
            )
          ) : (
            ''
          )}
        </div>
        <div className={styles['popup-item__tags']}>
          {props.product.sampleTag.map((tag, i) => {
            return <div key={i}>{tag.tagValue}</div>
          })}
        </div>
      </div>
    </div>
  )
}

export default ProductItem
